import { ref, computed, watch, ComputedRef } from 'vue';
import { useWindowSize, useDebounceFn } from '@vueuse/core';

/**
 * Provides a debounced reactive state for mobile detection based on window width.
 *
 * @returns {{ isMobile: ComputedRef<boolean> }} The reactive mobile detection state.
 */
export function useMobileDetection(): { isMobile: ComputedRef<boolean> } {
  const { width } = useWindowSize();
  const isMobile = ref(false);

  // Debounced update function
  const updateIsMobile = useDebounceFn(() => {
    isMobile.value = width.value <= 960;
    console.log(`isMobile: ${isMobile.value}`);
  }, 200); // Adjust debounce time as needed

  // Watch for changes in width and trigger debounced update
  watch(width, () => {
    updateIsMobile();
  });

  return {
    isMobile: computed(() => isMobile.value),
  };
}
