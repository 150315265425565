<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Heading from '@components/Heading.vue';

export interface TransferSettingFormInterface {
  name: string;
  server: string,
  username: string,
  password: string,
  type: client.FTPTypes,
  path: string,
  port?: number,
}

const {
  isSubmitting = false,
  data
} = defineProps<{
  isSubmitting?: boolean,
  data?: TransferSettingFormInterface
}>()

const emit = defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onSubmit', { name, server, username, password, type, path, port } : TransferSettingFormInterface): void;
}>();

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Name must be at least 3 characters long')
    .max(200, 'Name must be less than 200 characters long')
    .required('Name is required'),
  type: yup
    .string()
    .required('Type is required')
    .oneOf(['sftp', 'ftps'], 'Type must be either sftp or ftps'),
  server: yup
    .string()
    .required('Server is required')
    .when('type', {
      is: 'sftp',
      then: (schema) => schema.matches(/^sftp:\/\/.+$/, 'Address must be a valid SFTP address (e.g., sftp://hostname)')
    })
    .when('type', {
      is: 'ftps',
      then: (schema) => schema.matches(/^ftps:\/\/.+$/, 'Address must be a valid FTPS address (e.g., ftps://hostname)')
    }),
  username: yup
    .string()
    .required('Username is required'),
  password: yup
    .string()
    .required('Password is required'),
  path: yup
    .string()
    .required('Path is required'),
  port: yup
    .number()
    .transform((value) => isNaN(value) ? null : value )
    .nullable()
    .positive()
    .integer(),
});

const { handleSubmit } = useForm<TransferSettingFormInterface>({
  validationSchema: schema,
  initialValues: {
    name: data?.name,
    type: data?.type,
    server: data?.server,
    username: data?.username,
    password: data?.password,
    path: data?.path,
    port: data?.port,
  }
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: typeValue, errorMessage: typeError } = useField<string>('type', 'type');
const { value: serverValue, errorMessage: serverError } = useField<string>('server', 'server');
const { value: usernameValue, errorMessage: usernameError } = useField<string>('username', 'username');
const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password');
const { value: pathValue, errorMessage: pathError } = useField<string>('path', 'path');
const { value: portValue, errorMessage: portError } = useField<string>('port', 'port');

const onSubmit = handleSubmit(async (values) => {
  emit('onSubmit', values);
});

const ftpTypeOptions = [
  { value: client.FTPTypes.SFTP, name: 'SFTP' },
  { value: client.FTPTypes.FTPS, name: 'FTPS' },
]
</script>

<template>
  <ContainerCard>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Transfer Information
          </Heading>
          <p>Assign the setting to your views to export any images as they are received.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="client-name">Name</label>
              <input id="client-name"
                     v-model="nameValue"
                     type="text">
              <p class="message message-error">
                {{ nameError }}
              </p>
            </div>

            <div class="field">
              <label for="ftp-type">Type</label>
              <v-select v-model="typeValue"
                        label="name"
                        :clearable="false"
                        :searchable="false"
                        :reduce="(value: any) => value.value"
                        :options="ftpTypeOptions" />

              <p class="message message-error">
                {{ typeError }}
              </p>
            </div>
          </div>

          <div class="row-half">
            <div class="field">
              <label for="server-address">Server</label>
              <input id="server-address"
                     v-model="serverValue"
                     type="text">
              <p class="message message-error">
                {{ serverError }}
              </p>
            </div>

            <div class="field">
              <label for="path">Path</label>
              <input id="path"
                     v-model="pathValue"
                     type="text">
              <p class="message message-error">
                {{ pathError }}
              </p>
            </div>
          </div>

          <div class="row-half">
            <div class="field">
              <label for="username">Username</label>
              <input id="username"
                     v-model="usernameValue"
                     type="text">
              <p class="message message-error">
                {{ usernameError }}
              </p>
            </div>

            <div class="field">
              <label for="password">Password</label>
              <input id="password"
                     v-model="passwordValue"
                     type="password">
              <p class="message message-error">
                {{ passwordError }}
              </p>
            </div>
          </div>

          <div class="row-half">
            <div class="field">
              <label for="port">Port (optional)</label>
              <input id="port"
                     v-model="portValue"
                     type="number">
              <p class="message message-error">
                {{ portError }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         :is-outline-btn="true"
                         @click="() => emit('onCancel')">
          Cancel
        </ButtonComponent>
        <ButtonComponent :is-block-btn="true"
                         :type="ButtonType.Submit"
                         :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting">
          Save
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>
