<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { onMounted, onUnmounted, ref } from 'vue';
import Loading from '@components/Loading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { AlertVariant, ButtonSize, ButtonVariant, ImageSize } from '@viewModels/enums';
import { storeToRefs } from 'pinia';
import AlertBanner from '@components/AlertBanner.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import WallboardCard from '@components/cards/WallboardCard.vue';
import ModalComponent from '@components/ModalComponent.vue';
import { useMobileDetection } from '@utils/isMobile';
import ButtonIconGroup from '@components/ButtonIconGroup.vue';


// Types
type RefreshInterval = number | null;

// Stores
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

// Data
const activeViews = ref<client.View[]>([]);
const allFetchedViews = ref<client.View[]>([]);
const isReversed = ref<boolean>(false);
const allViewsCount = ref<number>(0);
const showOnlyImage = ref(false);
const showPublicViews = ref(false);

// CSS Grid Layout and Grid Sizes
const gridSizes = [200, 300, 400, 600];
// Use the last item in the array to set the max-width of the cards
const cardMaxWidth = gridSizes[gridSizes.length - 1] as number;
let currentGridIndex = gridSizes.indexOf(300);
const gridMinWidth = ref(gridSizes[currentGridIndex]);

// Refresh Fetch Interval
let refreshInterval: RefreshInterval; // Store the interval ID
const refreshIntervalTime = 30000; // Refresh interval every 30 seconds

// Image Modal
const { isMobile } = useMobileDetection();
const isImageModalVisible = ref(false);
const selectedImage = ref<client.View | undefined>(undefined);
const liveLastCaptured = ref<string>('');
const lastCapturedAt = ref<string>('');
const isLoadingImage = ref(false);

// Loading
const isLoading = ref<boolean>(false);


/**
 * Updates the list of active views.
 *
 * This function is responsible for updating the `activeViews`
 * It fetches the list of views, filters out the inactive views,
 * updates the existing views, and sorts the views by their last
 * captured UTC time.
 *
 * @async
 * @returns {Promise<void>}
 */
async function updateActiveViews(): Promise<void> {
  try {
    // Set the loading state to true if there are no active views for the initial render
    isLoading.value = activeViews.value.length === 0;

    // Fetch the views
    const views = await client.listViews({
      organisation: applicationStore.activeOrganisation!.id,
    } as client.ListViewsData);

    // Cache the fetched data for future use
    allFetchedViews.value = views.data;

    // Update the total count of views for the organisation
    allViewsCount.value = views.data.length;

    // Apply the initial filter to get the active views (default: show all active views)
    applyFilter();
  } catch (error) {
    console.error('Error updating views:', error);
  } finally {
    isLoading.value = false;
  }
}

/**
 * Applies filters to the fetched views based on their status and public visibility.
 *
 * This function filters out inactive views and views that are not public if the
 * `showPublicViews` flag is set to true. The resulting filtered views are then
 * assigned to the `activeViews` ref, updating the reactive view list.
 */
function applyFilter(): void {
  // Filter views based on ViewStatus and isPublic
  const filteredViews = allFetchedViews.value.filter((view) => {
    const isActive = view.status === client.ViewStatus.ACTIVE;
    const isVisible = showPublicViews.value ? view.isPublic : true; // Default: all active views
    return isActive && isVisible;
  });

  // Sort the filtered views by their last captured UTC time in descending order
  const sortedViews = filteredViews.sort((a, b) => {
    const dateA = a.lastCapturedUtc ? new Date(a.lastCapturedUtc).getTime() : 0;
    const dateB = b.lastCapturedUtc ? new Date(b.lastCapturedUtc).getTime() : 0;
    return dateB - dateA;
  });

  // Apply reversal if the isReversed flag is true
  activeViews.value = isReversed.value ? [...sortedViews].reverse() : [...sortedViews];
}

// Shuffle the views randomly
function shuffleViews(): void {
  activeViews.value = [...activeViews.value].sort(() => Math.random() - 0.5);
}

// Toggle CSS Grid Sizes
function toggleGridSize(): void {
  currentGridIndex = (currentGridIndex + 1) % gridSizes.length;
  gridMinWidth.value = gridSizes[currentGridIndex];
}

// Toggle show only images on the cards
function toggleShowOnlyImage(): void {
  showOnlyImage.value = !showOnlyImage.value;
}

// Reverse the order of the views
function reverseViews(): void {
  isReversed.value = !isReversed.value;
  activeViews.value.reverse();
}

// Toggle Public views
function togglePublicViews(): void {
  showPublicViews.value = !showPublicViews.value;
  applyFilter();
}

/**
 * This function is responsible for setting up the interval for refreshing views.
 * It calls the `updateActiveViews` function initially and then every `refreshIntervalTime` milliseconds.
 * The interval is cleared when the component is unmounted.
 */
onMounted(() => {
  // Update the active views initially
  updateActiveViews();
  // Set up the interval to update every `refreshIntervalTime`
  refreshInterval = setInterval(() => {
    updateActiveViews();
  }, refreshIntervalTime) as unknown as RefreshInterval;
});

/**
 * Clear the interval for refreshing views when the component is unmounted and reset `activeViews`.
 */
onUnmounted(() => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
  // Reset activeViews
  activeViews.value.length = 0;
});

// Modal Data
/**
 * Opens the modal and sets the selected image.
 */
const openImageModal = (view: client.View, captured: string, capturedAt: string): void => {
  if (!view.latestImageURL) return;

  isImageModalVisible.value = true;
  isLoadingImage.value = true;
  selectedImage.value = view;
  liveLastCaptured.value = captured;
  lastCapturedAt.value = capturedAt;

  const img = new Image();
  img.src = view.latestImageURL + (isMobile ? ImageSize.Medium : ImageSize.Large);
  img.onload = onImageLoad;
  img.onerror = onImageError;
};

/**
 * Closes the modal and clears the selected image.
 */
const closeImageModal = (): void => {
  selectedImage.value = undefined;
  isImageModalVisible.value = false;
};

/**
 * Handles image loading.
 */
const onImageLoad = (): void => {
  isLoadingImage.value = false;
};

/**
 * Handles image loading errors.
 */
const onImageError = (): void => {
  isLoadingImage.value = false;
  console.error('Failed to load the image');
};
</script>

<template>
  <div v-show="!isLoading" class="wallboard__header">
    <span>
      Displaying active views. This page will automatically refresh every {{ refreshIntervalTime / 1000 }} seconds.
    </span>
    <span class="wallboard__header--buttongroup">
      <ButtonIconGroup :variant="ButtonVariant.Dark">
        <ButtonComponent :is-block-btn="true"
                         :is-outline-btn="true"
                         :is-icon-btn="true"
                         :no-border="true"
                         :icon-name="isReversed ? IconName.BarsArrowDownIcon : IconName.BarsArrowUpIcon"
                         :icon-style="IconStyle.Outline"
                         :variant="ButtonVariant.Dark"
                         aria-label="Reverse the order of the views"
                         title="Reverse the order of the views"
                         @click="reverseViews" />
        <ButtonComponent :is-block-btn="true"
                         :is-outline-btn="true"
                         :is-icon-btn="true"
                         :no-border="true"
                         :icon-name="showOnlyImage ? IconName.PhotoIcon : IconName.QueueListIcon"
                         :icon-style="IconStyle.Outline"
                         :variant="ButtonVariant.Dark"
                         aria-label="Show or hide images"
                         title="Show or hide images"
                         @click="toggleShowOnlyImage" />
        <ButtonComponent v-if="!isMobile"
                         :is-block-btn="true"
                         :is-outline-btn="true"
                         :is-icon-btn="true"
                         :no-border="true"
                         :icon-name="IconName.ViewColumnsIcon"
                         :icon-style="IconStyle.Outline"
                         :variant="ButtonVariant.Dark"
                         aria-label="Change the layout"
                         title="Change the layout"
                         @click="toggleGridSize" />
        <ButtonComponent :is-block-btn="true"
                         :is-outline-btn="true"
                         :is-icon-btn="true"
                         :no-border="true"
                         :icon-name="showPublicViews ? IconName.EyeSlashIcon : IconName.EyeIcon"
                         :icon-style="IconStyle.Outline"
                         :variant="ButtonVariant.Dark"
                         aria-label="Show or hide public views"
                         title="Show or hide public views"
                         @click="togglePublicViews" />
      </ButtonIconGroup>
    </span>
  </div>

  <Loading v-if="isLoading" />
  <TransitionGroup v-else
                   name="fade"
                   tag="section"
                   class="wallboard__card-container"
                   :style="{ '--min-grid-width': `${gridMinWidth}px` }">
    <WallboardCard v-for="view in activeViews"
                   :key="view.id"
                   :view="view as client.View"
                   :show-only-image="showOnlyImage"
                   :card-max-width="cardMaxWidth"
                   @show-image-modal="openImageModal" />
  </TransitionGroup>

  <div class="mt-auto">
    <!-- Shuffle Alert Admin Only -->
    <AlertBanner v-if="adminMode && !isLoading"
                 :variant="AlertVariant.Info"
                 :icon-name="IconName.InformationCircleIcon"
                 :icon-style="IconStyle.Outline"
                 :has-bottom-margin="true">
      <template #mainContent>
        <div class="d-flex flex-column flex-lg-row justify-between align-center">
          <span class="mb-20 mb-lg-0">
            <strong>Admin Only Alert:</strong> Shuffle the cards, then wait for {{ refreshIntervalTime / 1000 }} seconds to see them automatically reorder as new data is fetched in the background.
          </span>
          <ButtonComponent :is-block-btn="true"
                           :size="ButtonSize.Small"
                           :variant="ButtonVariant.Info"
                           @click="shuffleViews">
            Shuffle Cards
          </ButtonComponent>
        </div>
      </template>
    </AlertBanner>
  </div>

  <!-- Image Modal -->
  <ModalComponent :visible="isImageModalVisible"
                  :heading-title="`View ${selectedImage?.name || 'Image'}`"
                  :is-large="true"
                  @on-close="closeImageModal">
    <template #modal-content>
      <Loading v-if="isLoadingImage" :is-absolute-positioned="true" />
      <div v-else>
        <img v-if="selectedImage?.latestImageURL"
             class="modal-image"
             :src="`${selectedImage.latestImageURL}${isMobile ? ImageSize.Medium : ImageSize.Large}`"
             alt="Image preview"
             :aria-hidden="true"
             @load="onImageLoad"
             @error="onImageError">
      </div>
    </template>
    <template #modal-footer>
      <div class="d-flex flex-column flex-lg-row justify-between align-start align-lg-center gap-10 w-100">
        <span class="text--size-5">Last Captured: {{ liveLastCaptured }} at {{ lastCapturedAt }}</span>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :is-outline-btn="true"
                         @click="closeImageModal">
          Close
        </ButtonComponent>
      </div>
    </template>
  </ModalComponent>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

.wallboard {
  &__header {
    position: sticky;
    top: -30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap-default;
    background-color: $neutral-50;
    z-index: 3;
    margin-bottom: $margin-bottom;

    @media screen and (width >= $breakpoint-lg) {
      flex-direction: row;
      align-items: center;

      margin-inline: -30px;
      padding-inline: 30px;
      padding-block: 10px;
    }

    &--buttongroup {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      @media screen and (width >= $breakpoint-lg) {
        justify-content: flex-end;
      }
    }
  }

  &__card-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--min-grid-width), 1fr));
    gap: clamp($gap-desktop, 2vw, $gap-default * 2);
    justify-items: center;
    margin-bottom: $gap-desktop;
  }
}


/* Fade Transition for TransitionGroup */
/* https://vuejs.org/guide/built-ins/transition-group#move-transitions */
/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>

<!--Modal Specific Styles-->
<style scoped lang="scss">
:deep(.modal__content--body) {
  aspect-ratio: 6 / 4;
  margin: auto;
}

.modal-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  aspect-ratio: 6 / 4;
  margin: auto;
}
</style>
