<template>
  <section class="pagination">
    <div class="pagination__dropdown">
      <slot name="pagination-dropdown" />
    </div>

    <div class="pagination__buttons">
      <slot name="pagination-buttons" />
    </div>
    
    <div class="pagination__info">
      <slot name="pagination-info" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.pagination {
  display: flex;
  flex-direction: column;
  gap: $gap-default;
  align-items: center;
  justify-content: center;

  @media screen and (width >= $breakpoint-lg) {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__dropdown {
    order: 1;
    width: 100%;

    @media screen and (width >= $breakpoint-lg) {
      width: auto;
    }

    :deep(.v-select) {
      width: 100%;
      margin: 0 auto;

      @media screen and (width >= $breakpoint-lg) {
        width: 170px;
      }
    }
  }

  &__info {
    order: 1;

    @media screen and (width >= $breakpoint-lg) {
      order: 3;
    }

    :deep(p) {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    gap: $gap-default;
    order: 3;

    @media screen and (width >= $breakpoint-lg) {
      order: 2;
    }
  }
}
</style>